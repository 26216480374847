import * as React from 'react';
import { useRequestBase, RequestResult, RequestError } from '@samc/react-ui-request';
import { getPortfolios } from '../Requests';
import { ApiContext, ApiContextType } from '../ApiContext';
import { CacheTime } from '../CacheTime';
import Portfolio from '../../viewmodels/domainReplicas/portfolio';

export const PORTFOLIOS_KEY = 'portfolios';

export const usePortfolios = (): RequestResult<Portfolio[]> => {
    const api = React.useContext<ApiContextType>(ApiContext);

    const staleTime = CacheTime.portfolios;

    const response = useRequestBase<Portfolio[], RequestError, ApiContextType, Parameters<typeof getPortfolios>>({
        key: PORTFOLIOS_KEY,
        query: getPortfolios,
        requestContext: ApiContext,
        params: [api.LiftSiftApi, api.AggregatorApi, api.requestInit],
        options: {
            staleTime,
        },
        // Trigger refresh of request cache if the headers change
        extraKeys: [JSON.stringify(api.requestInit?.headers)],
    });

    return response;
};
