import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IDropdownOption, Dropdown } from '@fluentui/react';
import { updateWorkbenchDataFilter } from '@samc/single-spa-workbench';
import { usePortfolios } from '../../api/hooks/usePortfolios';
import './PortfolioSelector.css';
import Portfolio from '../../viewmodels/domainReplicas/portfolio';
import {
    URL_PARAMNAME_DEFAULTS,
    URL_PARAMNAME_FILTER,
    PORTFOLIO_FIELDNAME_ID_AS_INCLUDED,
    PORTFOLIO_INCLUDED_FIELD_PREFIX,
    PORTFOLIO_FIELDNAME_ID_DIRECT_RELATION,
    PORTFOLIO_PLURAL_FIELDNAME_ID_AS_INCLUDED,
    PORTFOLIO_FIELDNAME_NAME,
    OPTIONAL_PORTFOLIO_FIELDNAME,
} from '../../portfolioDomainConstants';
import { decodeBase64ToObject, encodeBase64, encodeBase64FromObject } from '../../util';
import PortfolioDecode from '../../viewmodels/domainReplicas/portfolioDecode';

const getPortfolioFromStorage = (): Portfolio | null => {
    const portfolio = localStorage.getItem(PORTFOLIO_FIELDNAME_ID_AS_INCLUDED);
    try {
        return portfolio && portfolio.length ? JSON.parse(portfolio) : null;
    } catch {
        return null;
    }
};

const setPortfolioInStorage = (portfolio: Portfolio): void => {
    localStorage.setItem(PORTFOLIO_FIELDNAME_ID_AS_INCLUDED, JSON.stringify(portfolio));
};

const getFirstAvailablePortfolio = (portfolios: Portfolio[]): Portfolio | null =>
    portfolios && portfolios.length ? portfolios[0] : null;

const getPortfolioWithIdElseDefault = (portfolios: Portfolio[], id: string): Portfolio | null => {
    if (!portfolios || !portfolios.length) {
        return null;
    }

    const found = portfolios.find((p) => p.Id === id);
    if (found) {
        return found;
    }

    return getFirstAvailablePortfolio(portfolios);
};

// Strip out any generic portfolio fields and only leave those intended to be passed to child objects:
export const prunePortfolioObject = (portfolio: Portfolio): unknown => {
    const prunedPortfolio: { [k: string]: unknown } = {};
    const portfolioProps = Object.keys(portfolio);
    portfolioProps.forEach((p: string) => {
        if (p.startsWith(PORTFOLIO_INCLUDED_FIELD_PREFIX)) {
            prunedPortfolio[p] = portfolio[p];
        }
    });

    prunedPortfolio[PORTFOLIO_FIELDNAME_ID_AS_INCLUDED] = portfolio.Id;
    prunedPortfolio[PORTFOLIO_FIELDNAME_ID_DIRECT_RELATION] = portfolio.Id;

    return prunedPortfolio;
};

const emptyArray: Portfolio[] = [];
export const PortfolioSelector = (): React.ReactElement => {
    const portfolioQueryResults = usePortfolios();
    const portfolios = portfolioQueryResults.data || emptyArray;
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const params = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [selectedKey, setSelectedKey] = React.useState<string>();
    const [data, setData] = React.useState<Portfolio[]>();

    // Set data after request for portfolios completes
    React.useEffect(() => {
        const result = portfolioQueryResults;
        if (result && result.data && result.data.length > 0) {
            setData(result.data);
        }
    }, [portfolioQueryResults]);

    // Build our options
    const options = React.useMemo(
        () =>
            (data || []).map(
                (item: Portfolio): IDropdownOption => ({
                    key: String(item.Id),
                    text: String(item[PORTFOLIO_FIELDNAME_NAME]),
                }),
            ),
        [data],
    );

    // Get portfolio id from query string or local storage
    const getPortfolio = React.useCallback(() => {
        let portfolio: Portfolio | null;
        const portfolioId = params.has(PORTFOLIO_FIELDNAME_ID_DIRECT_RELATION)
            ? params.get(PORTFOLIO_FIELDNAME_ID_DIRECT_RELATION)
            : null;
        // try to pull from query param
        const defaults = params.has(URL_PARAMNAME_DEFAULTS) ? params.get(URL_PARAMNAME_DEFAULTS) : null;
        const retrieved = getPortfolioFromStorage();

        if (!defaults && portfolioId) {
            return getPortfolioWithIdElseDefault(portfolios, portfolioId);
        }

        if (defaults && defaults.length) {
            try {
                const { PortfolioId } = decodeBase64ToObject<PortfolioDecode>(defaults);
                if (PortfolioId && PortfolioId.length) return getPortfolioWithIdElseDefault(portfolios, PortfolioId);
            } catch (ex) {
                return getFirstAvailablePortfolio(portfolios);
            }
        }

        if (retrieved) {
            // pull from storage
            portfolio = retrieved;
            return getPortfolioWithIdElseDefault(portfolios, portfolio.Id);
        }
        // auto-select first option
        return getFirstAvailablePortfolio(portfolios);
    }, [portfolios, params]);

    // navigate to a new portfolio when changed
    const navigateToNewPortfolio = React.useCallback(
        (portfolio: Portfolio, appendToHistory?: boolean) => {
            params.set(PORTFOLIO_FIELDNAME_ID_DIRECT_RELATION, portfolio.Id); // deprecated; for backwards compatability only.

            const prunedPortfolio = prunePortfolioObject(portfolio);
            params.set(URL_PARAMNAME_DEFAULTS, encodeBase64FromObject(prunedPortfolio));

            const singularFilter = `[${PORTFOLIO_FIELDNAME_ID_AS_INCLUDED}]='${portfolio.Id}'`;
            const multiFilter = `DoesContain('${portfolio.Id}',[${PORTFOLIO_PLURAL_FIELDNAME_ID_AS_INCLUDED}])`;
            const optionalMultiFilter = `DoesContain('${portfolio.Id}',[${OPTIONAL_PORTFOLIO_FIELDNAME}]) OR ISNULL([${OPTIONAL_PORTFOLIO_FIELDNAME}])`;
            const filter = JSON.stringify([singularFilter, multiFilter, optionalMultiFilter]);
            params.set(URL_PARAMNAME_FILTER, encodeBase64(filter));

            const newPath = `${pathname}?${params.toString()}`;
            navigate(newPath, { replace: !appendToHistory });
        },
        [pathname, navigate, params],
    );

    const updateKey = React.useCallback(
        (key: string) => {
            setSelectedKey(key);
            const item = data?.find((x) => String(x.Id) === key) as unknown;
            if (item) {
                const forWorkbench = item as { [id: string]: unknown };
                updateWorkbenchDataFilter(forWorkbench);
            }
        },
        [data],
    );

    // change portfolio based on user selection
    const handlePortfolioChange = React.useCallback(
        (_event: unknown, newItem?: IDropdownOption): void => {
            if (newItem && newItem.key) {
                const key = String(newItem.key);
                updateKey(key);
                const portfolio = getPortfolioWithIdElseDefault(portfolios, key);
                if (portfolio) {
                    setPortfolioInStorage(portfolio);
                    navigateToNewPortfolio(portfolio, true);
                }
            }
        },
        [portfolios, navigateToNewPortfolio, updateKey],
    );

    // change portfolio based on query string or local storage
    React.useEffect(() => {
        if (!data) return;
        const portfolio = getPortfolio();
        if (portfolio && portfolio.Id !== selectedKey && typeof portfolio.Id === 'string') {
            updateKey(portfolio.Id);
            setPortfolioInStorage(portfolio);
            navigateToNewPortfolio(portfolio);
        }
    }, [data, getPortfolio, navigateToNewPortfolio, selectedKey, updateKey]);

    // if path was changed externally, add portfolio back in
    React.useEffect(() => {
        const portfolio = getPortfolio();
        if (portfolio && !params.has(URL_PARAMNAME_FILTER)) {
            navigateToNewPortfolio(portfolio);
        }
    }, [getPortfolio, navigateToNewPortfolio, params, pathname]);

    return (
        <div className="portfolio-selector">
            <Dropdown options={options} onChange={handlePortfolioChange} selectedKey={selectedKey} />
        </div>
    );
};

export default PortfolioSelector;
