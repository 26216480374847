import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { AuthorizationWrapper, useUserAuthenticationState } from '@samc/single-spa-authentication';
import { useTenantState } from '@samc/single-spa-tenant-selector';
import { Customizer, LayerHost } from '@fluentui/react';
import { useClientConfiguration } from '@samc/single-spa-client-configuration';
import { ApiProvider } from './api/ApiContext';
import { PortfolioSelector } from './pages/PortfolioSelector/PortfolioSelector';

interface Props {
    styletronEngine: Styletron;
}

const queryClient = new QueryClient();

const Root = (): React.ReactElement => {
    return <PortfolioSelector />;
};

const Wrapper = (props: Props): React.ReactElement => {
    const { styletronEngine } = props;

    const authState = useUserAuthenticationState();
    const userIsAuthenticated = React.useMemo(() => !!authState?.isAuthenticated, [authState]);
    const token = authState?.accessToken?.accessToken;

    const tenantName = useTenantState()?.name;

    const { result: clientConfiguration } = useClientConfiguration();

    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!userIsAuthenticated || tenantName === undefined || clientConfiguration === undefined) return <></>;

    const headers = {
        Authorization: `Bearer ${token}`,
        'X-Tenant': tenantName,
    };

    return (
        <AuthorizationWrapper
            config={{
                ...clientConfiguration,
                defaultHeaders: Object.entries(headers).map(([k, v]) => ({ name: k, value: v })),
            }}
            jwt={token}
        >
            <Customizer scopedSettings={{ Layer: { hostId: 'single-spa-portfolio-layerhost' } }}>
                <LayerHost
                    id="single-spa-portfolio-layerhost"
                    style={{
                        position: 'absolute',
                        zIndex: 2147483647, // max
                        top: 0,
                        pointerEvents: 'none',
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                />
                <StyletronProvider value={styletronEngine} debugAfterHydration>
                    <QueryClientProvider client={queryClient}>
                        <ApiProvider
                            value={{
                                requestInit: {
                                    headers,
                                },
                                LiftSiftApi: '/_apis/querybuilder',
                                AggregatorApi: '/screenaggregator',
                                ShowMessage: (_msg: string): void => undefined,
                            }}
                        >
                            <HashRouter>
                                <Root />
                            </HashRouter>
                        </ApiProvider>
                    </QueryClientProvider>
                </StyletronProvider>
            </Customizer>
        </AuthorizationWrapper>
    );
};

export default Wrapper;
