import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import singleSpaReact from 'single-spa-react';
import Root from './root.component';

const spaElementId = '@samc/single-spa-portfolio-selector';

const domElementGetter = (): HTMLElement => {
    let el = document.getElementById(spaElementId);
    if (!el) {
        el = document.createElement('div');
        el.id = spaElementId;
        document.body.appendChild(el);
    }

    return el;
};

const lifecycles = singleSpaReact({
    renderType: 'createRoot',
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(_err, _info, _props) {
        // Customize the root error boundary for your microfrontend here.
        return <></>;
    },
    domElementGetter,
});

export const { bootstrap, mount, unmount } = lifecycles;

export { usePortfolios } from './api/hooks/usePortfolios';
