import { mergeHeaders } from '@samc/react-ui-request';
import { PORTFOLIO_DOMAIN_ID, PORTFOLIO_FIELDNAME_NAME, PORTFOLIO_FIELDS_TO_QUERY } from '../portfolioDomainConstants';
import Portfolio from '../viewmodels/domainReplicas/portfolio';
import { QueryExecuteRequest } from '../viewmodels/requests/QueryExecuteRequest';
import { QueryExecuteResponse } from '../viewmodels/responses/QueryExecuteResponse';
import { GetHeaders } from './GetHeaders';
import { Domain } from './model/Domain';

const fetchInternal = async (input: RequestInfo, init?: RequestInit | undefined): Promise<Response> => {
    const response = await fetch(input, init);

    if (response.status >= 400 && response.status < 600) {
        const body = await response.json();
        if (body.developerMessage) {
            throw new Error(`${response.statusText}: ${body.developerMessage.message}`);
        }
        if (body.messages && body.messages.length > 0) {
            throw new Error(`${response.statusText}: ${body.messages[0]}`);
        }
    }

    return response;
};

export const executeQuery =
    (url: string, includeLimitedAccessRecords?: boolean) =>
    async (params: QueryExecuteRequest, requestInit?: RequestInit): Promise<QueryExecuteResponse> => {
        const response = await fetchInternal(
            `${url}/api/v2/Query/Execute${includeLimitedAccessRecords ? '?includeLimitedAccessRecords=true' : ''}`,
            {
                method: 'POST',
                headers: mergeHeaders(GetHeaders(), requestInit?.headers, { 'Content-Type': 'application/json' }),
                body: JSON.stringify(params),
            },
        );
        return response.json();
    };

export const getDomain = async (url: string, id: string, requestInit?: RequestInit): Promise<Domain> => {
    if (!id) {
        return Promise.reject(new Error('Invalid id'));
    }
    const response = await fetchInternal(`${url}/api/v1/domain/${id}`, {
        headers: mergeHeaders(GetHeaders(), requestInit?.headers),
    });
    return response.json();
};

export const getPortfolios = async (
    lsUrl: string,
    aggregatorUrl: string,
    requestInit?: RequestInit,
): Promise<Portfolio[]> => {
    const query = executeQuery(lsUrl, true);

    const domain = await getDomain(aggregatorUrl, PORTFOLIO_DOMAIN_ID, requestInit);

    const targetFields = PORTFOLIO_FIELDS_TO_QUERY.filter((field) => {
        // Safety check to ensure that the fields list actually matches the domain
        if (!domain.fields.some((f) => f.fieldName === field)) {
            // eslint-disable-next-line no-console
            console.error(
                `Error loading Portfolio Selector: Portfolio domain does not contain expected field [${field}]`,
            );
            return false;
        }
        return true;
    });

    const response = await query(
        {
            domainId: PORTFOLIO_DOMAIN_ID,
            adhocListViewMembers: targetFields.map((f) => ({
                scalarExpression: `[${f}]`,
                scalarDisplayName: f,
            })),
            filterIds: [],
            sorting: {
                order: 'asc',
                orderBy: {
                    scalarExpression: `[${PORTFOLIO_FIELDNAME_NAME}]`,
                },
            },
            paging: { start: 0, stop: 1000 },
            summaryMode: false,
        },
        requestInit,
    );

    return response.Data as Portfolio[];
};
